import { ref } from 'vue'
import { EMBED_MODE } from 'src/constants'

export const init_context = (mode) => ({
  id: "context",
  setup: {
    state: () => {
      const date_format = ref("YYYY-MM-DD");
      const mode = ref("edit")
      const group = ref(null)
      const app = ref(null)
      const agent = ref(null)
      const template = ref(null)
      const packages = ref([])
      const languages = ref([])
      const products = ref([])
      const language = ref({
        code: "en-US",
        name: "English",
      });
      const currencies = ref([])
      const currency = ref({
        code: "USD",
        name : "US Dollar"
      })
      const viewport = ref("desktop")
      const settings = ref({})
      const enable_submit_event = ref(false)

      return {
        app,
        agent,
        date_format,
        mode,
        template,
        packages,
        products,
        languages,
        language,
        viewport,
        group,
        currencies,
        currency,
        settings,
        enable_submit_event
      };
    },
    persist: mode === EMBED_MODE ? {
      paths: [
        'date_format',
        'mode',
        'group',
        'app',
        'agent',
        'template',
        'packages',
        'products',
        'languages',
        'language',
        'currencies',
        'currency',
        'viewport',
        'settings'
      ]
    } : {
      paths: ['currency','language' ]
    }
  },
});
