import {uid} from 'quasar'
import cloneDeep from 'lodash.clonedeep'

const generate_id = () => uid().replaceAll('-', '')

export const CE_TAG = 'egs-cms'
export const GRID_ROW_CLASS = 'egs-rows'
export const GRID_COLUMN_CLASS = 'egs-cols'

export const NODE_ID_ATTRIBUTE = 'egs-id'
export const NODE_TYPE_ATTRIBUTE = 'egs-type'
export const NODE_CODE_ATTRIBUTE = 'egs-code'
export const NODE_ORDER_ATTRIBUTE = 'egs-order'
export const NODE_CLASS = 'egs-nodes'
export const LAYOUT_RESIZE_BTN_CODE = 'Tools.LayoutResizers.Btns'
export const LAYOUT_RESIZER_CODE = 'Tools.LayoutResizers'
export const DROPZONE_HANDLER_CODE = 'Tools.DropzoneHandlers'
export const DROPZONE_PLACEHOLDER_CODE = 'Tools.DropzoneHandlers.Placeholders'
export const ELEMENT_RESIZE_BTN_CODE = 'Tools.ElementResizers.Btns'
export const ELEMENT_RESIZER_CODE = 'Tools.ElementsResizers'
export const NODE_CANVAS_TYPE = 'node-canvas'
export const LAYOUT_TYPE = 'layouts'
export const ELEMENT_TYPE = 'elements'
export const EDIT_TOOL_TYPE = 'edit-tools'
export const SECTION_CODE = 'Builtins.Sections'
export const HEADER_CODE = 'Builtins.Headers'
export const FOOTER_CODE = 'Builtins.Footers'
export const TAB_NODE_CODE = 'Builtins.Tabs'
export const SLIDESHOW_NODE_CODE = 'Builtins.Slideshows'
export const GRID_NODE_CODE = 'Builtins.Grids'
export const LOGIN_BAR_NODE_CODE = 'Builtins.LoginBars'
export const NAV_BAR_NODE_CODE = 'Builtins.NavBars'
export const CONTAINER_BOX_NODE_CODE = 'Builtins.ContainerBoxes'
export const MEDIA_NODE_CODE = 'Builtins.Media'
export const FORM_NODE_CODE = 'Builtins.Forms'
export const TEXT_NODE_CODE = 'Builtins.Texts'
export const FIELD_INPUT_NODE_CODE = 'Builtins.Forms.FieldInputs'
export const FORM_SUBMIT_BTN_NODE_CODE = 'Builtins.Forms.SubmitButtons'
export const FORM_FORGET_PW_BTN_CODE = 'Builtins.Forms.ForgetPasswordButtons'
export const FORM_ERROR_MSG_NODE_CODE = 'Builtins.Forms.ErrorMessages'
export const BUTTON_NODE_CODE = 'Builtins.Buttons'
export const PACKAGE_FORM_NODE_CODE = 'Builtins.PackageForms'
export const LANGUAGE_PICKER_NODE_CODE = 'Builtins.LanguagePickers'
export const CURRENCY_PICKER_NODE_CODE = 'Builtins.CurrencyPickers'
export const AVATAR_NODE_CODE = 'Builtins.Avatars'
export const TRIP_FORM_NODE_CODE = 'Builtins.TripForms'
export const PACKAGE_WIDGET_NODE_CODE = 'Builtins.PackageWidgets'
export const CAROUSE_NODE_CODE = 'Builtins.Carousel'
export const CAROUSE_CONTROL_NODE_CODE = 'Builtins.CarouselControl'

export const VERTICAL_AXIS_VECTOR = 'vertical'
export const HORIZONTAL_AXIS_VECTOR = 'horizontal'

export const DRAG_SNAP_RANGE = 3

export const NODE_RIGHT_SIDE = 'right'
export const NODE_LEFT_SIDE = 'left'
export const NODE_TOP_SIDE = 'top'
export const NODE_BOTTOM_SIDE = 'bottom'

export const NODE_CENTER = 'center'

export const NODE_SIDES = {
  [NODE_LEFT_SIDE]: {axis: VERTICAL_AXIS_VECTOR},
  [NODE_TOP_SIDE]: {axis: HORIZONTAL_AXIS_VECTOR},
  [NODE_RIGHT_SIDE]: {axis: VERTICAL_AXIS_VECTOR},
  [NODE_BOTTOM_SIDE]: {axis: HORIZONTAL_AXIS_VECTOR},
}

export const NODE_EDGES = {
  [`${NODE_TOP_SIDE}-${NODE_LEFT_SIDE}`]: [NODE_TOP_SIDE, NODE_LEFT_SIDE],
  [`${NODE_TOP_SIDE}-${NODE_RIGHT_SIDE}`]: [NODE_TOP_SIDE, NODE_RIGHT_SIDE],
  [`${NODE_BOTTOM_SIDE}-${NODE_LEFT_SIDE}`]: [NODE_BOTTOM_SIDE, NODE_LEFT_SIDE],
  [`${NODE_BOTTOM_SIDE}-${NODE_RIGHT_SIDE}`]: [NODE_BOTTOM_SIDE, NODE_RIGHT_SIDE],
  [NODE_LEFT_SIDE]: [NODE_LEFT_SIDE],
  [NODE_TOP_SIDE]: [NODE_TOP_SIDE],
  [NODE_RIGHT_SIDE]: [NODE_RIGHT_SIDE],
  [NODE_BOTTOM_SIDE]: [NODE_BOTTOM_SIDE],
}

export const NODE_CORNERS = {
  [`${NODE_TOP_SIDE}-${NODE_LEFT_SIDE}`]: {
    sides: [NODE_TOP_SIDE, NODE_LEFT_SIDE],
    index: 0
  },
  [`${NODE_TOP_SIDE}-${NODE_RIGHT_SIDE}`]: {
    sides: [NODE_TOP_SIDE, NODE_RIGHT_SIDE],
    index: 1
  },
  [`${NODE_BOTTOM_SIDE}-${NODE_LEFT_SIDE}`]: {
    sides: [NODE_BOTTOM_SIDE, NODE_LEFT_SIDE],
    index: 3
  },
  [`${NODE_BOTTOM_SIDE}-${NODE_RIGHT_SIDE}`]: {
    sides: [NODE_BOTTOM_SIDE, NODE_RIGHT_SIDE],
    index: 2
  },
}

export const RESIZING_EDGES = {
  top: 'egs-resize-btn-top',
  left: 'egs-resize-btn-left',
  right: 'egs-resize-btn-right',
  bottom: 'egs-resize-btn-bottom',
}

export const RESIZING_SIDES = {
  [NODE_TOP_SIDE]: {handler_class: 'egs-resize-btn-top'},
  [NODE_LEFT_SIDE]: {handler_class: 'egs-resize-btn-left'},
  [NODE_RIGHT_SIDE]: {handler_class: 'egs-resize-btn-right'},
  [NODE_BOTTOM_SIDE]: {handler_class: 'egs-resize-btn-bottom'},
}

export const DRAGGING_VERTICAL_DIRECTION = 'vertical'
export const DRAGGING_HORIZONTAL_DIRECTION = 'horizontal'

export const DRAGGING_DIRECTIONS = {
  [DRAGGING_VERTICAL_DIRECTION]: {sides: [NODE_TOP_SIDE, NODE_BOTTOM_SIDE]},
  [DRAGGING_HORIZONTAL_DIRECTION]: {sides: [NODE_LEFT_SIDE, NODE_RIGHT_SIDE]},
}

export const MIN_DESKTOP_SCREEN_WIDTH = 1024
export const MIN_TABLET_SCREEN_WIDTH = 440

export const SECTION_TOOL_COLOR = '#6f56f9'
export const HEADER_TOOL_COLOR = '#ffa500'
export const FOOTER_TOOL_COLOR = '#ffa500'
export const ELEMENT_TOOL_COLOR = '#1976d2'

export const DESKTOP_VIEWPORT = 'desktop'
export const MOBILE_VIEWPORT = 'mobile'
export const TABLET_VIEWPORT = 'tablet'

export const VIEWPORTS = [
  {
    id: DESKTOP_VIEWPORT,
    label: 'Desktop',
    icon: 'desktop_windows',
  },
  {
    id: TABLET_VIEWPORT,
    label: 'Tablet',
    icon: 'tablet',
  },
  {
    id: MOBILE_VIEWPORT,
    label: 'Mobile',
    icon: 'smartphone',
  }
]

export const VISIBILITY_NOT_LOGIN = 'not-logged-in'
export const VISIBILITY_LOGIN = 'logged-in'

export const DEFAULT_VIEWPORT = DESKTOP_VIEWPORT

export const DEFAULT_LANGUAGE = {
  code: 'en-US',
  name: 'English'
}

export const MAX_EDIT_HISTORY = 5

export const NODE_BASE_PROPS = {
  node: {type: Object, required: true},
  styles: {type: Object, required: true},
  parent: {type: Object},
  viewport: {type: String, default: DEFAULT_VIEWPORT},
  language: {type: Object, default: () => DEFAULT_LANGUAGE}
}

export const SINGLE_MEDIA_BACKGROUND_TYPE = 'single-media'
export const MULTIPLE_MEDIA_BACKGROUND_TYPE = 'multiple-media'
export const COLOR_BACKGROUND_TYPE = 'color'
export const NO_BACKGROUND_TYPE = 'none'

export const BACKGROUND_IMAGE_FREEZE_EFFECT = 'freeze'
export const BACKGROUND_IMAGE_PARALLAX_EFFECT = 'parallax'
export const BACKGROUND_IMAGE_NONE_EFFECT = 'none'

export const ON_SUBMIT_EVENT = 'on-submit'

export const CUSTOM_EVENTS = [
  ON_SUBMIT_EVENT
]

export const BACKGROUND_TYPES = [
  {
    id: NO_BACKGROUND_TYPE,
    label: 'No Background'
  },
  {
    id: SINGLE_MEDIA_BACKGROUND_TYPE,
    label: 'Single Media'
  },
  {
    id: MULTIPLE_MEDIA_BACKGROUND_TYPE,
    label: 'Multiple Media'
  },
  {
    id: COLOR_BACKGROUND_TYPE,
    label: 'Color'
  },
]

export const BACKGROUND_IMAGE_EFFECTS = [
  {
    id: BACKGROUND_IMAGE_NONE_EFFECT,
    label: 'No Effect'
  },
  {
    id: BACKGROUND_IMAGE_PARALLAX_EFFECT,
    label: 'Parallax'
  },
  {
    id: BACKGROUND_IMAGE_FREEZE_EFFECT,
    label: 'Freeze'
  },
]

export const TEXT_INPUT_TYPE = 'text'
export const TEXTAREA_INPUT_TYPE = 'textarea'
export const PASSWORD_INPUT_TYPE = 'password'
export const COUNTRY_SELECT_INPUT_TYPE = 'country-select'
export const FILE_PICKER_INPUT_TYPE = 'file-picker'

export const STRING_DATA_TYPE = 'string'
export const NUMBER_DATA_TYPE = 'number'
export const FILE_DATA_TYPE = 'file'

export const INPUT_TYPES = {
  [TEXT_INPUT_TYPE]: {data_type: STRING_DATA_TYPE, label: 'Text'},
  [TEXTAREA_INPUT_TYPE]: {data_type: STRING_DATA_TYPE, label: 'Textarea'},
  [PASSWORD_INPUT_TYPE]: {data_type: STRING_DATA_TYPE, label: 'Password'},
  [COUNTRY_SELECT_INPUT_TYPE]: {
    data_type: STRING_DATA_TYPE,
    label: 'Country Select'
  },
  [FILE_PICKER_INPUT_TYPE]: {data_type: FILE_DATA_TYPE, label: 'File Picker'},
}

export const LOGIN_FORM_TYPE = 'login'
export const REGISTER_FORM_TYPE = 'register'
export const AGENT_REGISTRATION_FORM_TYPE = 'agent-registration'

export const FORM_TYPES = {
  [LOGIN_FORM_TYPE]: {label: 'Login'},
  [AGENT_REGISTRATION_FORM_TYPE]: {label: 'Agent Registration'},
  // [REGISTER_FORM_TYPE]: {label: 'Register Form'}
}

export const EDIT_MODE = 'edit'
export const PREVIEW_MODE = 'preview'
export const PUBLISH_MODE = 'publish'
export const EMBED_MODE = 'embed'

export const EDITOR_MINIMIZE_RATIO = 0.5

export const ADDING_LAYOUT_NODE_TYPE = 'add-layouts'
export const ADDING_ELEMENT_NODE_TYPE = 'add-elements'

export const REGULAR_PAGE_TYPE = 'regular'
export const ERROR_PAGE_TYPE = 'error'

export const PAGE_TYPES = [
  {
    id: REGULAR_PAGE_TYPE,
    label: 'Regular'
  },
  {
    id: ERROR_PAGE_TYPE,
    label: 'Error'
  }
]

export const B2B_GROUP = 'B2B'
export const B2C_GROUP = 'B2C'
export const BOOKING_APP_GROUP = 'BookingApp'
export const GROUPS = [
  B2B_GROUP,
  B2C_GROUP,
  // BOOKING_APP_GROUP
]

export const NO_ACTION_EVENT_HANDLER = {
  id: 'no-action',
  label: 'No action'
}

export const PACKAGE_FORM_POPUP_EVENT_HANDLER = {
  id: 'package-form-popup',
  label: 'Popup a package form'
}

export const PAGE_REDIRECT_EVENT_HANDLER = {
  id: 'page-redirect',
  label: 'Redirect to a page'
}

export const LINK_REDIRECT_EVENT_HANDLER = {
  id: 'link-redirect',
  label: 'Redirect to a link'
}

export const B2B_DASHBOARD_REDIRECT_HANDLER = {
  id: 'b2b-dashboard-redirect',
  label: 'Redirect to B2B Dashboard Portal'
}

export const DEFAULT_PACKAGE_FORM_DESIGN = 'Default'
export const EXPEDIA_PACKAGE_FORM_DESIGN = 'Expedia'
export const CACHE_VERSION = "0.0.8"

export const SMALL_SIZE = 'sm'
export const EXTRA_SMALL_SIZE = 'xs'
export const MEDIUM_SIZE = 'md'
export const LARGE_SIZE = 'lg'
export const EXTRA_LARGE_SIZE = 'xl'


export const SIZES = [
  EXTRA_SMALL_SIZE,
  SMALL_SIZE,
  MEDIUM_SIZE,
  LARGE_SIZE,
  EXTRA_LARGE_SIZE
]

export const JUSTIFY_CONTENTS = [
  'justify-start',
  'justify-end',
  'justify-center',
  'justify-between',
  'justify-around',
  'justify-evenly',
]

export const ITEMS_ALIGNS = [
  'items-start',
  'items-end',
  'items-center',
  'items-baseline',
]

export const CONTENT_ALIGNS = [
  'content-start',
  'content-end',
  'content-center',
  'content-stretch',
  'content-between',
  'content-around',
]

export const SELF_ALIGNS = [
  'self-start',
  'self-center',
  'self-end',
  'self-baseline',
  'self-stretch',
]

export const DEFAULT_BUTTON_DESIGN = 'default'
export const OUTLINE_BUTTON_DESIGN = 'outline'
export const PUSH_BUTTON_DESIGN = 'push'
export const TEXT_BUTTON_DESIGN = 'flat'
export const GLOSSY_BUTTON_DESIGN = 'glossy'

export const BUTTON_DESIGNS = [
  {
    id: DEFAULT_BUTTON_DESIGN,
    label: 'Default'
  },
  {
    id: TEXT_BUTTON_DESIGN,
    label: 'Text'
  },
  {
    id: OUTLINE_BUTTON_DESIGN,
    label: 'Outline'
  },
  {
    id: PUSH_BUTTON_DESIGN,
    label: 'Push'
  },
  {
    id: GLOSSY_BUTTON_DESIGN,
    label: 'Glossy'
  }
]

export const BUTTON_SHAPE_ROUND = {
  id: 'round',
  label: 'Round'
}

export const BUTTON_SHAPE_SQUARE = {
  id: 'square',
  label: 'Square'
}

export const BUTTON_SHAPES = [
  BUTTON_SHAPE_SQUARE,
  BUTTON_SHAPE_ROUND
]

export const DEFAULT_INPUT_DESIGN = 'default'
export const OUTLINE_INPUT_DESIGN = 'outlined'

export const INPUT_DESIGNS = [
  {
    id: DEFAULT_INPUT_DESIGN,
    label: 'Default'
  },
  {
    id: OUTLINE_INPUT_DESIGN,
    label: 'Outline'
  },
]

export const DEFAULT_TAB_DESIGN = {
  id: 'default',
  label: 'Default'
}

export const BUTTON_TAB_DESIGN = {
  id: 'button',
  label: 'Button'
}

export const CUSTOM_TAB_DESIGN_1 = {
  id: 'custom1',
  label: 'Custom style 1'
}

export const TAB_DESIGNS = [
  DEFAULT_TAB_DESIGN,
  BUTTON_TAB_DESIGN,
  CUSTOM_TAB_DESIGN_1
]

export const CAROUSE_CONTROL_DESIGNS = ['Default', 'Dot', 'Arrow']


const INIT_BASIC_NODE = () => ({
  id: generate_id(),
  code: null,
  type: null,
  parent_id: null,
  scope_type: null,
  scope_id: null,
  group: null,
  order: 0,
  layout: {
    columns: {
      enable: true,
      gap: {
        enable: false,
        size: 'md'
      }
    },
    justify_content: 'justify-start',
    items_align: 'items-start',
    content_align: 'content-start',
    no_wrap: false,
    reverse_wrap: false
  },
  dimension: {
    min_height: 0,
    min_width: 0,
    max_width: 0,
    width: 0,
    height: 0,
    columns: {
      desktop: 4,
      tablet: 6,
      mobile: 12
    },
  },
  dropping: {
    enable: false,
    invalid_kinds: []
  },
  visibility: {
    viewports: [DESKTOP_VIEWPORT, TABLET_VIEWPORT, MOBILE_VIEWPORT],
    auths: [VISIBILITY_LOGIN, VISIBILITY_NOT_LOGIN],
  },
  relation: {
    undetachable_kinds: []
  },
  background: {
    enable: false,
    type: 'none',
    file: null,
    color: null,
    files: [],
    time_interval: 5000,
    darken: false
  },
  styles: {
    corners: {
      enable: false,
      sync: true,
      values: [5, 5, 5, 5],
    },
    border: {
      enable: false,
      opacity: 100,
      color: '#000000',
      width: 1
    },
    shadow: {
      enable: false,
      angle: 0,
      blur: 5,
      size: 0,
      distance: 1,
      opacity: 1,
      color: '#000000',
    },
    margin: {
      left: {
        auto: false,
        value: 0
      },
      right: {
        auto: false,
        value: 0
      },
      bottom: {
        auto: false,
        value: 0
      },
      top: {
        auto: false,
        value: 0
      },
    },
    position: {
      left: {
        value: 0,
        enable: false
      },
      right: {
        value: 0,
        enable: false
      },
      bottom: {
        value: 0,
        enable: false
      },
      top: {
        value: 0,
        enable: false
      }
    },
    padding: {
      left: {
        value: 0
      },
      right: {
        value: 0
      },
      bottom: {
        value: 0
      },
      top: {
        value: 0
      },
    },
  },
  meta: {},
  custom_events: {},
  children: []
})

export const EDITOR_EVENT_CREATED_STATUS = 'created'
export const EDITOR_EVENT_PROCESSING_STATUS = 'processing'
export const EDITOR_EVENT_PROCESSED_STATUS = 'processed'
export const EDITOR_EVENT_FAILED_STATUS = 'failed'

const INIT_NODE_EVENT_HANDLER = () => ({
  handler: NO_ACTION_EVENT_HANDLER.id,
  page_endpoint: null,
  link: null,
  product_combine: null,
  destination: null,
  package_form_color: '#1976d2'
})

export const NODE_KINDS = {
  [HEADER_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = LAYOUT_TYPE
      result.code = HEADER_CODE

      result.dropping.enable = true
      result.background.enable = true

      result.layout.justify_content = 'justify-center'

      result.styles.padding.top.value = 16
      result.styles.padding.bottom.value = 16
      result.styles.padding.left.value = 16
      result.styles.padding.right.value = 16

      result.dimension.columns.mobile = 12
      result.dimension.columns.tablet = 12
      result.dimension.columns.desktop = 12
      result.dimension.min_height = 60

      return result
    }
  },
  [FOOTER_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = LAYOUT_TYPE
      result.code = FOOTER_CODE

      result.dropping.enable = true
      result.background.enable = true

      result.layout.justify_content = 'justify-center'

      result.styles.padding.top.value = 16
      result.styles.padding.bottom.value = 16
      result.styles.padding.left.value = 16
      result.styles.padding.right.value = 16

      result.dimension.columns.mobile = 12
      result.dimension.columns.tablet = 12
      result.dimension.columns.desktop = 12
      result.dimension.min_height = 100

      return result
    }
  },
  [SECTION_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = LAYOUT_TYPE
      result.code = SECTION_CODE

      result.background.enable = true
      result.dropping.enable = true

      result.layout.justify_content = 'justify-center'

      result.styles.padding.top.value = 100
      result.styles.padding.bottom.value = 16
      result.styles.padding.left.value = 16
      result.styles.padding.right.value = 16

      result.dimension.columns.mobile = 12
      result.dimension.columns.tablet = 12
      result.dimension.columns.desktop = 12
      result.dimension.min_height = 200

      return result
    }
  },
  [CURRENCY_PICKER_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = CURRENCY_PICKER_NODE_CODE
      result.dimension.width = 200

      result.meta = {
        label: {
          [DEFAULT_LANGUAGE.code]: 'Currency'
        },
        prepend_icon: null,
        append_icon: null,
        type: 'select',
        shape: 'default',
        design: 'outlined',
        dense: false
      }

      return result
    }
  },
  [LANGUAGE_PICKER_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = LANGUAGE_PICKER_NODE_CODE
      result.dimension.width = 200

      result.meta = {
        label: {
          'en-US': 'Language'
        },
        prepend_icon: null,
        append_icon: null,
        type: 'select',
        shape: 'default',
        design: 'outlined'
      }

      return result
    }
  },
  [TEXT_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = TEXT_NODE_CODE

      result.dimension.min_height = 10

      result.meta = {
        content: {
          [DEFAULT_LANGUAGE.code]: 'Sample Text'
        }
      }

      return result
    }
  },
  [BUTTON_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = BUTTON_NODE_CODE
      result.styles.corners.enable = true
      result.dimension.width = 100

      result.meta = {
        label: {
          [DEFAULT_LANGUAGE.code]: 'Click Me'
        },
        label_enabled: true,
        prepend_icon: null,
        size: MEDIUM_SIZE,
        shape: BUTTON_SHAPE_SQUARE.id,
        design: DEFAULT_BUTTON_DESIGN,
        color: '#1976d2',
        text_color: '#ffffff',
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        }
      }

      return result
    }
  },
  [TAB_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = TAB_NODE_CODE

      result.dropping.enable = true
      result.dimension.min_height = 100
      Object.keys(NODE_SIDES).forEach(side => {
        result.styles.padding[side].value = 24
      })

      result.meta = {
        tab_id: '1',
        tabs: [
          {
            id: '1',
            label: {
              [DEFAULT_LANGUAGE.code]: 'Tab 1'
            },
            icon: null,
          },
        ],
        flat: false,
        bordered: false,
        transparent_background: false,
        colors: {
          active: '#1976d2',
          text: '#000000',
          background: '#ffffff'
        },
        align: 'left',
        design: DEFAULT_TAB_DESIGN.id,
        stretch: false,
        separator: false,
      }

      return result
    }
  },
  [NAV_BAR_NODE_CODE]: {
    generate_navigation: () => {
      return {
        id: generate_id(),
        icon: {
          enable: false,
          value: null
        },
        label: {
          [DEFAULT_LANGUAGE.code]: 'Nav Item 1'
        },
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        }
      }
    },
    generate: function () {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = NAV_BAR_NODE_CODE

      result.styles.corners.enable = true

      result.meta = {
        design: 'default',
        transparent_background: true,
        colors: {
          background: '#ffffff',
          text: '#000000'
        },
        title: {
          enable: true,
          value: {
            [DEFAULT_LANGUAGE.code]: 'Navbar title'
          }
        },
        left_drawer: {
          enable: true,
          icon: 'menu'
        },
        right_drawer: {
          enable: true,
          icon: 'menu'
        },
        navigations: [
          this.generate_navigation()
        ]
      }

      return result
    }
  },
  [LOGIN_BAR_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = LOGIN_BAR_NODE_CODE

      result.meta = {
        before_login: {
          label: {
            [DEFAULT_LANGUAGE.code]: 'Login'
          }
        },
        after_login: {
          callback: INIT_NODE_EVENT_HANDLER(),
          logout_label: {
            [DEFAULT_LANGUAGE.code]: 'Logout'
          }
        },
        after_logout: {
          callback: INIT_NODE_EVENT_HANDLER()
        },
        design: 'avatar',
        size: 'md',
        dropdown_icon: {
          enable: false,
          icon: 'arrow_drop_down'
        },
        navigation_menu: []
      }

      return result
    }
  },
  [PACKAGE_FORM_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = PACKAGE_FORM_NODE_CODE

      result.meta = {
        product_codes: ['flight'],
        color: '#1976d2'
      }

      return result
    }
  },
  [TRIP_FORM_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = TRIP_FORM_NODE_CODE

      result.meta = {
        color: '#1976d2'
      }

      return result
    }
  },
  [FORM_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = FORM_NODE_CODE

      result.dropping.enable = true
      result.dropping.invalid_kinds = [FORM_NODE_CODE]

      result.styles.corners.enable = true
      result.layout.columns.gap.enable = true
      result.layout.columns.gap.value = 24

      Object.keys(NODE_SIDES).forEach(side => {
        result.styles.padding[side].value = 24
      })

      result.relation.undetachable_kinds = [
        FIELD_INPUT_NODE_CODE,
        FORM_ERROR_MSG_NODE_CODE,
        FORM_SUBMIT_BTN_NODE_CODE
      ]

      result.meta = {
        type: 'login',
        after_submit: INIT_NODE_EVENT_HANDLER(),
        values: {
          email: '',
          password: ''
        },
        flat: false,
        bordered: false,
      }

      return result
    }
  },
  [FIELD_INPUT_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = FIELD_INPUT_NODE_CODE

      result.meta = {
        type: 'text',
        key: 'name',
        label: {
          [DEFAULT_LANGUAGE.code]: 'Name'
        },
        placeholder: {
          [DEFAULT_LANGUAGE.code]: 'Input your name'
        },
        required: false,
        readonly: false,
        has_regex: false,
        multiple: false,
        range: false,
        dense: false,
        regex_type: null,
        prepend_icon: null,
        append_icon: null,
        option_label: null,
        option_value: null,
        min_length: null,
        min_value: null,
        max_value: null,
        shape: 'default',
        design: 'default',
        options: [],
      }

      return result
    }
  },
  [FORM_SUBMIT_BTN_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = FORM_SUBMIT_BTN_NODE_CODE

      result.styles.corners.enable = true

      result.meta = {
        label: {
          [DEFAULT_LANGUAGE.code]: 'Submit'
        },
        placeholder: {
          [DEFAULT_LANGUAGE.code]: 'Input your name'
        },
        shape: 'default',
        design: 'default',
        color: '#1976d2',
        text_color: '#ffffff',
        size: MEDIUM_SIZE
      }

      return result
    }
  },
  [FORM_FORGET_PW_BTN_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = FORM_FORGET_PW_BTN_CODE

      result.styles.corners.enable = true

      result.meta = {
        label: {
          [DEFAULT_LANGUAGE.code]: 'Forget Password?'
        },
        shape: 'default',
        design: 'default',
        color: '#1976d2',
        text_color: '#ffffff',
        size: MEDIUM_SIZE,
      }

      return result
    }
  },
  [FORM_ERROR_MSG_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = FORM_ERROR_MSG_NODE_CODE
      result.dimension.min_height = 40

      return result
    }
  },
  [MEDIA_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = MEDIA_NODE_CODE

      result.dimension.width = 200
      result.dimension.height = 100
      result.styles.corners.enable = true

      result.meta = {
        file: null,
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        },
        ratios: {
          [DESKTOP_VIEWPORT]: 1,
          [TABLET_VIEWPORT]: 1.5,
          [MOBILE_VIEWPORT]: 2,
        },
        lock_ratio: true,
        show_title: false,
        show_caption: false,
        title: {
          [DEFAULT_LANGUAGE.code]: 'Title',
        },
        caption: {
          [DEFAULT_LANGUAGE.code]: 'Caption',
        },
        label: {
          enable: false,
          value: {
            [DEFAULT_LANGUAGE.code]: 'Label'
          },
          styles: cloneDeep(result.styles),
          background: cloneDeep(result.background)
        },
      }

      return result
    }
  },
  [CONTAINER_BOX_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = CONTAINER_BOX_NODE_CODE

      result.dimension.min_height = 50
      result.dimension.min_width = 100

      result.background.enable = true
      result.dropping.enable = true

      result.meta = {}

      return result
    }
  },
  [AVATAR_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = AVATAR_NODE_CODE
      result.styles.corners.enable = true
      result.dimension.width = 50
      result.dimension.height = 50

      result.meta = {
        colors: {
          icon: '#000000',
          background: '#ffffff',
        },
        transparent_background: false,
        type: 'icon',
        icon: 'flight',
        file: {
          url: 'https://cdn.quasar.dev/img/avatar.png'
        },
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        }
      }

      return result
    }
  },
  [SLIDESHOW_NODE_CODE]: {
    generate_slide: file => {
      return {
        id: generate_id(),
        file: file,
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        },
        title: {
          [DEFAULT_LANGUAGE.code]: 'Image Title'
        },
        ratios: {
          [DESKTOP_VIEWPORT]: 1,
          [TABLET_VIEWPORT]: 1.5,
          [MOBILE_VIEWPORT]: 2,
        },
        lock_ratio: true,
        caption: {
          [DEFAULT_LANGUAGE.code]: 'Image Caption'
        },
        show_title: false,
        show_caption: false
      }
    },
    generate: function () {
      let result = INIT_BASIC_NODE()
      result.type = ELEMENT_TYPE
      result.code = SLIDESHOW_NODE_CODE

      result.dimension.min_height = 50
      result.dimension.min_width = 50
      result.dimension.height = 667
      result.dimension.width = 1000
      result.styles.corners.enable = true

      const image_urls = [
        'https://easygds.s3.ap-southeast-1.amazonaws.com/staging/media/System/1691519208.530862__amazing-sunrise-in-mount-bromo.jpg',
      ]

      result.meta = {
        interval_time: 3000,
        design: 'default',
        items_to_show: {
          mobile: 1,
          desktop: 1,
          tablet: 1
        },
        space_between: {
          mobile: 8,
          desktop: 16,
          tablet: 12
        },
        start_from_middle: false,
        pagination: false,
        navigation: false,
        infinite: false,
        thumbnails: false,
        autoplay: false,
        ratio: 2,
        ratios: {
          [DESKTOP_VIEWPORT]: 2,
          [TABLET_VIEWPORT]: 2,
          [MOBILE_VIEWPORT]: 2,
        },
        lock_ratio: true,
        slides: image_urls.map(url => this.generate_slide({url: url}))
      }

      return result
    }
  },
  [GRID_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = GRID_NODE_CODE
      result.styles.corners.enable = true

      const default_urls = [
        'https://flyone.eu/media/72179284-1fa3-4736-99c8-b3f56db0198d/Qz0xjg/images/destinations_page/Chisinau-destination.jpg',
      ]

      const init_cell = (id, url) => ({
        id: id,
        file: {
          url: url
        },
        show_title: false,
        show_caption: false,
        title: {
          [DEFAULT_LANGUAGE.code]: ''
        },
        caption: {
          [DEFAULT_LANGUAGE.code]: ''
        },
        events: {
          click: INIT_NODE_EVENT_HANDLER()
        }
      })

      const cells = []

      default_urls.forEach((url, index) => {
        cells.push(init_cell(index.toString(), url))
      })

      result.meta = {
        design: 'default',
        cells: cells,
        columns: {
          mobile: 1,
          tablet: 2,
          desktop: 4
        },
        cell_gap: 8,
        cell_type: 'Media'
      }

      return result
    }
  },
  [PACKAGE_WIDGET_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = PACKAGE_WIDGET_NODE_CODE
      result.styles.corners.enable = true
      result.background.enable = true
      result.dimension.width = 100

      result.meta = {
        design: 'default',
        package_id: null,
        place: null,
        has_price: false,
        flat: false,
        bordered: false,
        price: 0,
        ratios: {
          [DESKTOP_VIEWPORT]: 0.5,
          [TABLET_VIEWPORT]: 1,
          [MOBILE_VIEWPORT]: 2,
        },
        currency_code: 'USD',
        colors: {
          text: '#ffffff',
          form: '#3e85f8'
        }
      }

      return result
    }
  },
  [CAROUSE_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = CAROUSE_NODE_CODE

      result.dropping.enable = true
      result.dimension.min_height = 100
      Object.keys(NODE_SIDES).forEach((side) => {
        result.styles.padding[side].value = 24
      })
      const slides = [
        {
          id: '1',
          order: 1
        }
      ]
      result.meta = {
        slide_id: slides[0].id,
        slides,
        flat: false,
        bordered: false,
        transparent_background: false,
        colors: {
          active: '#1976d2',
          text: '#000000',
          background: '#ffffff'
        },
        align: 'left',
        design: DEFAULT_TAB_DESIGN.id,
        stretch: false,
        separator: false
      }

      return result
    }
  },
  [CAROUSE_CONTROL_NODE_CODE]: {
    generate: () => {
      let result = INIT_BASIC_NODE()

      result.type = ELEMENT_TYPE
      result.code = CAROUSE_CONTROL_NODE_CODE

      result.meta = {
        slide_id: '',
        design: CAROUSE_CONTROL_DESIGNS[0],
        color: '#f5772f'
      }

      return result
    }
  },
}


export const BLOCK_CATEGORIES = [
  {
    'id': '1',
    'name': 'Blank Sections',
    'type': 'layouts',
    'display_columns': 1,
    'blocks': [
      {
        'name': 'Blank Sections',
        'avatar_type': 'media',
        'avatar': {
          'file': {}
        },
        'nodes': [
          {
            'type': 'layouts',
            'code': 'Builtins.Sections',
          }
        ]
      },
    ]
  },
  {
    'id': '2',
    'name': 'Tabs',
    'display_columns': 2,
    'type': 'layouts',
    'blocks': [
      {
        'name': 'Section with Package Tabs',
        'avatar_type': 'media',
        'avatar': {
          'file': {}
        },
        'nodes': [
          {
            "type": "layouts",
            "code": "Builtins.Sections",
            "children": [
              {
                "type": "elements",
                "code": "Builtins.Tabs",
                "meta": {
                  "tab_id": 0,
                  "tabs": [
                    {
                      "id": 0,
                      "label": {
                        "en-US": "Hotels"
                      },
                      "icon": null,
                      "order": 0
                    },
                    {
                      "id": 1,
                      "label": {
                        "en-US": "Flights"
                      },
                      "icon": null,
                      "order": 1
                    },
                    {
                      "id": 2,
                      "label": {
                        "en-US": "Bundle"
                      },
                      "icon": null,
                      "order": 2
                    }
                  ]
                },
                "children": [
                  {
                    "type": "elements",
                    "code": "Builtins.PackageForms",
                    "meta": {
                      "product_codes": [
                        "hotel"
                      ],
                      "tab_id": 0
                    },
                  },
                  {
                    "type": "elements",
                    "code": "Builtins.PackageForms",
                    "meta": {
                      "product_codes": [
                        "flight"
                      ],
                      "tab_id": 1
                    },
                  },
                  {
                    "type": "elements",
                    "code": "Builtins.PackageForms",
                    "meta": {
                      "product_codes": [
                        "flight",
                        "hotel"
                      ],
                      "tab_id": 2
                    },
                  }
                ],
              }
            ]
          }
        ]
      },
    ]
  },

  {
    'id': '3',
    'name': 'Texts',
    'display_columns': 1,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Heading 1',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h1>Heading 1</h1>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h1>Heading 1</h1>'
              }
            },
          }
        ]
      },
      {
        'name': 'Heading 2',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h2>Heading 2</h2>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h2>Heading 2</h2>'
              }
            },
          }
        ]
      },
      {
        'name': 'Heading 3',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h3>Heading 3</h3>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h3>Heading 3</h3>'
              }
            },
          }
        ]
      },
      {
        'name': 'Heading 4',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h4>Heading 4</h4>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h4>Heading 4</h4>'
              }
            },
          }
        ]
      },
      {
        'name': 'Heading 5',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h5>Heading 5</h5>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h5>Heading 5</h5>'
              }
            },
          }
        ]
      },
      {
        'name': 'Heading 6',
        'avatar_type': 'text',
        'avatar': {
          'text': '<h6>Heading 6</h6>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<h6>Heading 6</h6>'
              }
            },
          }
        ]
      },
      {
        'name': 'Subtitle 1',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-subtitle1">Subtitle 1</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-subtitle1">Subtitle 1</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Subtitle 2',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-subtitle2">Subtitle 2</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-subtitle2">Subtitle 2</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Caption Text',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-caption">Caption Text</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-caption">Caption Text</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Paragraph',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Strike Paragraph',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-body1 text-strike">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-body1 text-strike">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Italic Paragraph',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-body1 text-italic">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-body1 text-italic">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
              }
            },
          }
        ]
      },
      {
        'name': 'Bold Paragraph',
        'avatar_type': 'text',
        'avatar': {
          'text': '<span class="text-body1 text-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Texts',
            'meta': {
              'content': {
                'en-US': '<span class="text-body1 text-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>'
              }
            },
          }
        ]
      },
    ]
  },
  {
    'id': '4',
    'name': 'Buttons',
    'display_columns': 3,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Button',
        'avatar_type': 'html',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.Buttons',
          }
        ]
      },
    ]
  },
  {
    'id': generate_id(),
    'name': 'Avatars & Icons',
    'display_columns': 3,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Avatar',
        'avatar_type': 'node',
        'nodes': [
          {
            'type': 'elements',
            'code': AVATAR_NODE_CODE,
          }
        ]
      },
    ]
  },
  {
    'id': generate_id(),
    'name': 'Widgets',
    'display_columns': 3,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Package Widget',
        'avatar_type': 'node',
        'nodes': [
          {
            'type': 'elements',
            'code': PACKAGE_WIDGET_NODE_CODE,
          }
        ]
      },
    ]
  },
  {
    'id': '5',
    'name': 'Tabs',
    'display_columns': 2,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Tabs',
        'avatar_type': 'media',
        'nodes': [
          {
            "type": "elements",
            "code": "Builtins.Tabs",
          }
        ]
      },
      {
        name: 'Basic Carousel',
        avatar_type: 'media',
        nodes: [
          {
            type: 'elements',
            code: CAROUSE_NODE_CODE
          }
        ]
      },
      {
        name: 'Basic Carousel Control',
        avatar_type: 'media',
        nodes: [
          {
            type: 'elements',
            code: CAROUSE_CONTROL_NODE_CODE
          }
        ]
      }
    ]
  },
  {
    'id': '6',
    'name': 'Slideshows',
    'display_columns': 2,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Slideshow',
        'avatar_type': 'media',
        'nodes': [
          {
            'type': 'elements',
            'code': SLIDESHOW_NODE_CODE,
          }
        ]
      },
    ]
  },
  {
    'id': '7',
    'name': 'Grids',
    'display_columns': 2,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Grid',
        'avatar_type': 'media',
        'block_category': {
          'name': 'Grids',
          'type': 'elements'
        },
        'nodes': [
          {
            'type': 'elements',
            'code': GRID_NODE_CODE,
          }
        ]
      },
    ]
  },
  {
    'id': '8',
    'name': 'Boxes',
    'display_columns': 2,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Box',
        'avatar_type': 'media',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.ContainerBoxes',
          }
        ]
      },
    ]
  },
  {
    'id': '9',
    'name': 'Forms',
    'display_columns': 2,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Package Form',
        'avatar_type': 'media',
        'block_category': {
          'name': 'Forms',
          'type': 'elements'
        },
        'nodes': [
          {
            "type": "elements",
            "code": PACKAGE_FORM_NODE_CODE,
          }
        ]
      },
      {
        'name': 'Trip Form',
        'avatar_type': 'media',
        'block_category': {
          'name': 'Forms',
          'type': 'elements'
        },
        'nodes': [
          {
            "type": "elements",
            "code": TRIP_FORM_NODE_CODE,
          }
        ]
      },
      {
        'name': 'Login Form',
        'avatar_type': 'media',
        'nodes': [
          {
            "type": "elements",
            "code": FORM_NODE_CODE,
            'dimension': {
              'columns': {
                [DESKTOP_VIEWPORT]: 4,
                [TABLET_VIEWPORT]: 6,
                [MOBILE_VIEWPORT]: 12,
              }
            },
            "children": [
              {
                "type": "elements",
                "code": "Builtins.Texts",
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 12,
                    [TABLET_VIEWPORT]: 12,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
                "meta": {
                  "content": {
                    "en-US": "<span class=\"text-h3\">Sign In</span>"
                  }
                },
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 12,
                    [TABLET_VIEWPORT]: 12,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
                "meta": {
                  "type": "text",
                  "key": "email",
                  "label": {
                    "en-US": "Email"
                  },
                  "placeholder": {
                    "en-US": "Input your email"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": true,
                  "regex_type": "email",
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "min_height": 45,
                  "shape": "default",
                  "design": "outlined"
                },
                "children": [],
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 12,
                    [TABLET_VIEWPORT]: 12,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
                "meta": {
                  "type": "password",
                  "key": "password",
                  "label": {
                    "en-US": "Password"
                  },
                  "placeholder": {
                    "en-US": "Input your password"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "min_height": 45,
                  "shape": "default",
                  "design": "outlined"
                },
                "children": [],
              },
              {
                "type": "elements",
                "code": FORM_SUBMIT_BTN_NODE_CODE,
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 6,
                    [TABLET_VIEWPORT]: 6,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
                "meta": {
                  "label": {
                    "en-US": "Proceed To Account"
                  },
                  "shape": "default",
                  "design": "default",
                  "color": "#1976d2",
                  "text_color": "#ffffff"
                },
              },
              {
                "type": "elements",
                "code": FORM_FORGET_PW_BTN_CODE,
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 6,
                    [TABLET_VIEWPORT]: 6,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
                "meta": {
                  "label": {
                    "en-US": "Forget Password?"
                  },
                  "shape": "default",
                  "design": "default",
                  "color": "#000000",
                  "text_color": "#ffffff",
                }
              },
              {
                "type": "elements",
                "code": FORM_ERROR_MSG_NODE_CODE,
                dimension: {
                  columns: {
                    [DESKTOP_VIEWPORT]: 12,
                    [TABLET_VIEWPORT]: 12,
                    [MOBILE_VIEWPORT]: 12,
                  }
                },
              },
            ],
          }
        ]
      },
      {
        'name': 'B2B Agent Registration Form',
        'avatar_type': 'media',
        'block_category': {
          'name': 'Forms',
          'type': 'elements'
        },
        'nodes': [
          {
            "type": "elements",
            "code": "Builtins.Forms",
            "meta": {
              "type": "agent-registration",
              "after_submit": {
                "handler": "page-redirect",
                "page_endpoint": "/login",
                "link": null
              },
              "values": {
                "email": "",
                "name": "",
                "phone": "",
                "iata": "",
                "address": "",
                "city": "",
                "country": "",
                "turnover": "",
                "documents": []
              },
              "flat": false,
              "bordered": false,
            },
            "children": [
              {
                "type": "elements",
                "code": TEXT_NODE_CODE,
                "meta": {
                  "content": {
                    "en-US": "<div style=\"width: 100%;\"><span class=\"text-h3\"><font style=\"font-size: 38px;\">Register Account</font></span></div>"
                  }
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "name",
                  "label": {
                    "en-US": "Agency Name"
                  },
                  "placeholder": {
                    "en-US": "Input agency name"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "phone",
                  "label": {
                    "en-US": "Telephone Number"
                  },
                  "placeholder": {
                    "en-US": "Input your agency phone"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "email",
                  "label": {
                    "en-US": "Email"
                  },
                  "placeholder": {
                    "en-US": "Input your agency email"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": true,
                  "regex_type": "email",
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "file-picker",
                  "key": "documents",
                  "label": {
                    "en-US": "Upload BR Copy"
                  },
                  "placeholder": {
                    "en-US": ""
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": true,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "address",
                  "label": {
                    "en-US": "Street Address"
                  },
                  "placeholder": {
                    "en-US": "Input your agency address"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "iata",
                  "label": {
                    "en-US": "IATA Registration"
                  },
                  "placeholder": {
                    "en-US": "Input your agency IATA"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "country-select",
                  "key": "country",
                  "label": {
                    "en-US": "Country"
                  },
                  "placeholder": {
                    "en-US": "Input your agency country"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "min_height": 45,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "city",
                  "label": {
                    "en-US": "City/Suburb"
                  },
                  "placeholder": {
                    "en-US": "Input your agency city/suburb"
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FIELD_INPUT_NODE_CODE,
                "meta": {
                  "type": "text",
                  "key": "turnover",
                  "label": {
                    "en-US": "Expected Monthly Turnover"
                  },
                  "placeholder": {
                    "en-US": ""
                  },
                  "required": true,
                  "readonly": false,
                  "has_regex": false,
                  "regex_type": null,
                  "prepend_icon": null,
                  "append_icon": null,
                  "multiple": false,
                  "range": false,
                  "option_label": null,
                  "option_value": null,
                  "options": [],
                  "min_length": null,
                  "min_value": null,
                  "max_value": null,
                  "shape": "default",
                  "design": "outlined"
                }
              },
              {
                "type": "elements",
                "code": FORM_SUBMIT_BTN_NODE_CODE,
                "meta": {
                  "label": {
                    "en-US": "Create Account"
                  },
                  "shape": "default",
                  "design": "default",
                  "color": "#1976d2",
                  "text_color": "#ffffff"
                }
              },
              {
                "type": "elements",
                "code": BUTTON_NODE_CODE,
                "meta": {
                  "label": {
                    "en-US": "To Login Page"
                  },
                  "shape": "default",
                  "design": "outline",
                  "color": "#1976d2",
                  "text_color": "#1976d2",
                  "events": {
                    "click": {
                      "handler": "page-redirect",
                      "page_endpoint": "/login"
                    }
                  }
                },
              },
              {
                "type": "elements",
                "code": FORM_ERROR_MSG_NODE_CODE,
              },
            ]
          }
        ]
      },
    ]
  },
  {
    'id': '10',
    'name': 'Toolbars',
    'display_columns': 3,
    'type': 'elements',
    'blocks': [
      {
        'name': 'Basic Login Bar',
        'avatar_type': 'media',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.LoginBars'
          }
        ]
      },
      {
        'name': 'Nav Bar',
        'avatar_type': 'media',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.NavBars'
          }
        ]
      },
      {
        'name': 'Currency Picker',
        'avatar_type': 'none',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.CurrencyPickers'
          }
        ]
      },
      {
        'name': 'Language Picker',
        'avatar_type': 'none',
        'nodes': [
          {
            'type': 'elements',
            'code': 'Builtins.LanguagePickers'
          }
        ]
      },

    ]
  }
]

