import {reactive} from 'vue'


export default {
  id: 'auth',
  setup: {
    state: () => {
      const editor = reactive({
        accesses: {},
        account_id: null,
        access_token: null,
        email: null,
        name: null,
        account_type: null,
        scope_type: null,
        scope_id: null
      })

      const user = reactive({
        accesses: {},
        account_id: null,
        access_token: null,
        email: null,
        name: null,
        account_type: null,
        scope_type: null,
        scope_id: null
      })

      return {
        editor,
        user
      }
    },
    persist: true
  }
}

