import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import key_by from 'lodash.keyby'

import PlaceSelect from 'src/components/common/place-select'
import DatePicker from 'src/components/common/date-picker'
import SearchBtn from '../search-btn'
import TimePicker from 'src/components/common/time-picker/index.vue'
import { QInput } from 'quasar'
import DifferentDropoffLocation from './different-dropoff-location/index.vue'
import { get_field_settings, PACKAGE_CAR_RENTAL_IDS } from 'src/composables/utils/forms'

export const get_meta_default = (params) => {
  const { t } = useI18n()
  const { props, rules, place_types, payload } = params
  const setting_default = props.setting?.meta?.default?.fields ?? {}
  const field_setting = key_by(setting_default, 'id') ?? {}

  return computed(() => ({
    fields: [
      {
        id: PACKAGE_CAR_RENTAL_IDS.PICKUP_LOCATION,
        component: PlaceSelect,
        hidden: false,
        model: 'pickup_location',
        order: 1,
        events: {},
        bind: {
          dense: true,
          types: place_types,
          rules: rules.place,
          outlined: true,
          'hide-bottom-space': true,
          'hide-selected': true,
          'fill-input': true,
          prepend_icon: 'place',
          label: t('common.pickup-location')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.PICKUP_LOCATION,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.PICKUP_DATE,
        component: DatePicker,
        hidden: false,
        model: 'pickup_date',
        order: 2,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          rules: rules.dates,
          booking_cutoff_days: props.car_rental.booking_cutoff_days,
          'hide-bottom-space': true,
          label: t('common.pickup-date')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.PICKUP_DATE,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.PICKUP_TIME,
        component: TimePicker,
        hidden: false,
        model: 'pickup_time',
        order: 2,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          rules: rules.dates,
          'hide-bottom-space': true,
          label: t('common.pickup-time')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.PICKUP_TIME,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },

      {
        id: PACKAGE_CAR_RENTAL_IDS.DRIVER_AGE,
        component: QInput,
        hidden: false,
        model: 'driver_age',
        order: 3,
        events: {},
        bind: {
          dense: true,
          type: 'number',
          outlined: true,
          'hide-bottom-space': true,
          rules: rules.place,
          label: t('common.driver-age')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.DRIVER_AGE,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_LOCATION,
        component: DifferentDropoffLocation,
        hidden: false,
        model: 'diff_dropoff',
        order: 4,
        events: {},
        bind: {
          place_types,
          form_data: payload.value.dropoff_data
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_LOCATION,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_DATE,
        component: DatePicker,
        hidden: false,
        model: 'dropoff_date',
        order: 5,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          rules: rules.dates,
          booking_cutoff_days: props.car_rental.booking_cutoff_days,
          'hide-bottom-space': true,
          label: t('common.dropoff-date')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_DATE,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_TIME,
        component: TimePicker,
        hidden: false,
        model: 'dropoff_time',
        order: 6,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          rules: rules.dates,
          'hide-bottom-space': true,
          label: t('common.dropoff-time')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.DROPOFF_TIME,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_CAR_RENTAL_IDS.SEARCH_BTN,
        component: SearchBtn,
        hidden: false,
        disabled: false,
        model: 'payload',
        order: 7,
        events: {},
        bind: {
          label: t('common.search-now'),
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_CAR_RENTAL_IDS.SEARCH_BTN,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      }
    ]
  }))
}
