import {Notify, Dialog, Quasar} from 'quasar'
import {createPinia, setActivePinia} from 'pinia'
import piniaPersist from 'pinia-plugin-persistedstate'
import MasonryWall from '@yeger/vue-masonry-wall'
import App from './embed.ce.vue'
import router from './router'
import {register as register_swiper} from 'swiper/element/bundle'
import create_app from './app'
import { i18n } from "src/composables/i18n";


register_swiper()

const pinia = createPinia()
pinia.use(piniaPersist)
setActivePinia(pinia)

create_app(App, {
  plugins: [
    {plugin: i18n},
    {plugin: pinia},
    {plugin: router},
    {plugin: MasonryWall},
    {
      plugin: Quasar, options: { plugins: { Notify, Dialog } }
    },
  ],
  links: [
    {
      href: "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons",
      rel: "stylesheet", type: "text/css"
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded",
      rel: "stylesheet", type: "text/css"
    },
    {
      href: "https://cdn.jsdelivr.net/npm/quasar@2.13.0/dist/quasar.prod.css",
      rel: "stylesheet", type: "text/css"
    },
  ]
})
