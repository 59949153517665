export default {
  mounted(el, binding) {
    const innerHTML = el.innerHTML
    const escaped_pattern = binding.value.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&'
    )
    const regex = new RegExp(escaped_pattern, 'gi')

    el.innerHTML = innerHTML.replace(regex, function (matchedText) {
      return `<span style="background-color: yellow;">${matchedText}</span>`
    })
  },
  updated(el, binding) {
    const innerText = el.innerText
    const escaped_pattern = binding.value.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&'
    )
    const regex = new RegExp(escaped_pattern, 'gi')

    el.innerHTML = innerText.replace(regex, function (matchedText) {
      return `<span style="background-color: yellow;">${matchedText}</span>`
    })
  }
}
