<template>
  <header :style="styles">
    <slot></slot>
  </header>
</template>
<script setup>
import {computed, defineProps} from 'vue'
import {NO_BACKGROUND_TYPE, NODE_BASE_PROPS} from 'src/constants'
import {generate_common_node_styles} from 'src/composables/canvas'
import {stores} from 'src/stores'


const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()


const styles = computed(() => {
  let result = generate_common_node_styles(props.node)

  return result
})

</script>
