<template>
  <form-builder
    v-if="payload"
    :key="update_key"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
    :node_id="node.id"
    :style="{ '--q-primary': color }"
    product_name="flight"
    @submit="submit"
  />
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import key_by from 'lodash.keyby'
import clone_deep from 'lodash.clonedeep'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'
import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'
import { get_meta_expedia } from './meta_expedia'

const props = defineProps({
  flight: { type: Object, required: true },
  product: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  setting: { type: Object },
  place_types: { type: Array, default: () => [] }

})

const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['flight']
)
const update_key = ref(0)

onMounted(async () => {
  if (payload.value && props.default_destination) {
    payload.value.ar_airport = props.default_destination
  }
  const default_values = props.setting?.default_values ?? {}
  if (!default_values) return
  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }
  if (default_values?.fl_round_trip) {
    payload.value.fl_round_trip = default_values.fl_round_trip
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  if (default_values?.cabin_class) {
    payload.value.cabin_class = default_values.cabin_class
  }
  if (default_values?.ar_airport) {
    payload.value.ar_airport = default_values.ar_airport
  }
  if (default_values?.dp_airport) {
    payload.value.dp_airport = default_values.dp_airport
  }
  update_key.value += 1
})

const rules = {
  dp_airport: [(v) => !!v || ''],
  ar_airport: [(v) => !!v || ''],
  dates: [(val) => !!val || ''],
  place: [(val) => !!val || ''],
  cabin_class: [(val) => !!val || ''],
  travelers: []
}

const emit = defineEmits(['submit'])
const traveler_limits = key_by(props.flight.traveler_limits, 'traveler_type')

const handle_swap_airport = () => {
  const origin = clone_deep(payload.value.dp_airport)
  const destination = clone_deep(payload.value.ar_airport)
  payload.value.ar_airport = origin
  payload.value.dp_airport = destination
}

const submit = () => {
  const {
    travelers,
    ar_airport,
    dp_airport,
    cabin_class,
    fl_round_trip,
    dates
  } = payload.value || {}

  let origin_type_dp_airport = 'airport_code',
    origin_type_ar_airport = 'airport_code'

  if (
    ['city', 'multi_city_vicinity', 'province_state'].includes(dp_airport?.type)
  ) {
    origin_type_dp_airport = 'city_code'
  }

  if (
    ['city', 'multi_city_vicinity', 'province_state'].includes(ar_airport?.type)
  ) {
    origin_type_ar_airport = 'city_code'
  }

  const data = {
    process: 'flight',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.flight.id,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      start_place_code: dp_airport?.code,
      start_place_type: origin_type_dp_airport,
      des_code: ar_airport?.code,
      des_type: origin_type_ar_airport,
      fl_cabin_class: cabin_class,
      fl_departure_date: fl_round_trip ? dates[0] : dates,
      fl_return_date: fl_round_trip ? dates[1] : dates,
      fl_round_trip: fl_round_trip
    })
  }
  if (props.flight_campaign) {
    data.flight_campaign = props.flight_campaign
  }
  if (props.partner_id) {
    data.partner_id = props.partner_id
  }
  emit('submit', data)
}

const meta_expedia = get_meta_expedia({
  props,
  rules,
  place_types: props.place_types,
  payload,
  traveler_limits,
  handle_swap_airport
})

const meta = get_meta_default({
  props,
  rules,
  payload,
  place_types: props.place_types,
  traveler_limits,
  handle_swap_airport
})
</script>
