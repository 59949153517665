<template>
  <div ref="container" :style="container_styles">
    <component :is="components[field_type]"
                lazy-rules
               v-model="form_node.meta.values[node.meta.key]"
               @filter="filter_options"
               @update:modelValue="handle_changes"
               v-bind="field_bind">
      <template v-if="prepend_icon" v-slot:prepend>
        <q-icon :name="prepend_icon"/>
      </template>
      <template v-slot:append>
        <q-icon :name="append_icon" v-if="append_icon"/>
        <template v-if="field_type === FILE_PICKER_INPUT_TYPE">
          <q-icon name="upload"/>
        </template>
      </template>
      <template v-if="context_store.mode === 'edit'" v-slot:hint>
        <q-item-label lines="1">Bottom space (error messages / hints)
        </q-item-label>
      </template>
    </component>
  </div>
</template>
<script setup>
import {computed, defineProps, ref} from 'vue'
import {
  INPUT_TYPES,
  FORM_NODE_CODE,
  NODE_BASE_PROPS,
  PASSWORD_INPUT_TYPE,
  STRING_DATA_TYPE,
  TEXT_INPUT_TYPE,
  TEXTAREA_INPUT_TYPE,
  COUNTRY_SELECT_INPUT_TYPE,
  FILE_PICKER_INPUT_TYPE
} from 'src/constants'
import COUNTRIES from 'src/constants/countries'
import {
  generate_common_node_styles,
  get_form_node_from_child
} from 'src/composables/canvas'
import {stores} from 'src/stores'
import {regex_validators} from 'src/composables/utils'
import {QField, QFile, QInput, QSelect} from 'quasar'
import {use_services} from 'src/composables/services'


const props = defineProps(NODE_BASE_PROPS)
const services = use_services()

const components = {
  [TEXT_INPUT_TYPE]: QInput,
  [PASSWORD_INPUT_TYPE]: QInput,
  [TEXTAREA_INPUT_TYPE]: QInput,
  [COUNTRY_SELECT_INPUT_TYPE]: QSelect,
  [FILE_PICKER_INPUT_TYPE]: QFile,
}

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const form_node = computed(() => get_form_node_from_child(props.node))

const loading = ref(false)


const handle_changes = async val => {
  if (!form_node.value) return
  const meta = props.node.meta || {}

  if (field_type.value === FILE_PICKER_INPUT_TYPE) {
    let files
    if (meta.multiple) {
      files = val
    }
    else {
      files = [val]
    }
    loading.value = true

    let final_value = []

    for (let file of files) {
      const form_data = new FormData()

      form_data.append('file', file)
      form_data.append('type', 'agent-documents')
      form_data.append('object_type', 'App')
      form_data.append('object_id', context_store.app.id)
      form_data.append('scope_type', 'App')
      form_data.append('scope_id', context_store.app.id)

      const response = await services.CmsService.upload_file(form_data)

      if (response.status === 200) {
        final_value.push(response.data)
      }
    }
    loading.value = false
    if (!meta.multiple) {
      final_value = final_value[0]
    }
    form_node.value.meta.values[props.node.meta.key] = final_value
  }
  else {
    form_node.value.meta.values[props.node.meta.key] = val
  }
}

const container_styles = computed(() => {
  let result = generate_common_node_styles(props.node)
  return result
})

const field_styles = computed(() => {
  let result = {
    'width': '100%',
  }
  return result
})

const field_type = computed(() => {
  return props.node.meta.type
})

const data_type = computed(() => {
  return INPUT_TYPES[field_type.value].data_type
})

const prepend_icon = computed(() => {
  return props.node.meta?.prepend_icon
})

const append_icon = computed(() => {
  return props.node.meta?.append_icon
})

const options = ref([])
if (field_type.value === COUNTRY_SELECT_INPUT_TYPE) {
  options.value = COUNTRIES
}

const filter_options = (search_text, update, abort) => {
  if (search_text && search_text.trim()) {
    if (field_type.value === COUNTRY_SELECT_INPUT_TYPE) {
      options.value = COUNTRIES.filter(c => {
        return (
          c.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1
          || c.code.toLowerCase().indexOf(search_text.toLowerCase()) > -1
        )
      })
    }
  }
  else {
    if (field_type.value === COUNTRY_SELECT_INPUT_TYPE) {
      options.value = COUNTRIES
    }
  }
  update()
}

const field_bind = computed(() => {
  const meta = props.node.meta

  let result = {
    'style': field_styles.value,
    'dense': true,
    'hide-bottom-space': true,
    'label': meta.label[context_store.language.code],
    'placeholder': meta.placeholder[context_store.language.code],
    'loading': loading.value,
    'disable': loading.value,
  }

  if (meta.shape && meta.shape !== 'default') {
    result[meta.shape] = true
  }

  if (meta.design && meta.design !== 'default') {
    result[meta.design] = true
  }

  if (meta.readonly) {
    result['readonly'] = true
  }

  const rules = []
  if (meta.required) {
    const require_validator = val => {
      if (data_type.value === STRING_DATA_TYPE) {
        return (!!val && !!val.trim()) || 'Required'
      }
      return true
    }
    rules.push(require_validator)
  }
  if (data_type.value === STRING_DATA_TYPE) {
    if (meta.has_regex && meta.regex_type) {
      const regex_validator = regex_validators[meta.regex_type]
      if (regex_validator) {
        rules.push(
          val => regex_validator(val) || `Invalid ${meta.regex_type}`
        )
      }
    }
  }

  if ([
    COUNTRY_SELECT_INPUT_TYPE
  ].includes(field_type.value)) {

    result['use-input'] = true
    result['fill-input'] = true
    result['emit-value'] = true
    result['map-options'] = true
    result['option-label'] = 'name'
    result['option-value'] = 'code'
    result['hide-selected'] = true

    if (meta.multiple) {
      result['multiple'] = true
      result['use-chips'] = true
    }
    result['options'] = options.value
  }
  else if (field_type.value === FILE_PICKER_INPUT_TYPE) {
    if (meta.multiple) {
      result['multiple'] = true
      result['use-chips'] = true
    }
  }
  else {
    result['type'] = field_type.value
  }

  result['rules'] = rules

  return result
})

</script>
