import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import key_by from 'lodash.keyby'
import DatePicker from 'src/components/common/date-picker'
import TravelerSelect from 'src/components/common/traveler-select'
import SearchBtn from '../search-btn'
import LocationPicker from './location-picker/index.vue'
import TimePicker from 'src/components/common/time-picker/index.vue'
import { get_field_settings, PACKAGE_TRANSFER_IDS } from 'src/composables/utils/forms'

export const get_meta_default = (params) => {
  const { t } = useI18n()
  const { props, rules, traveler_limits, payload } = params
  const setting_default = props.setting?.meta?.default?.fields ?? {}
  const field_setting = key_by(setting_default, 'id') ?? {}

  return computed(() => ({
    fields: [
      {
        id: PACKAGE_TRANSFER_IDS.AIRPORT,
        component: LocationPicker,
        hidden: false,
        disabled: false,
        model: 'payload',
        order: 1,
        events: {},
        bind: {
          node: props.node,
          from_airport: payload.value?.from_airport,
          default_destination: props.default_destination
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRANSFER_IDS.AIRPORT,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 6,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRANSFER_IDS.DESTINATION,
        component: TravelerSelect,
        hidden: false,
        disabled: false,
        model: 'travelers',
        order: 3,
        events: {},
        bind: {
          dense: true,
          rules: rules.travelers,
          max_adults: +traveler_limits?.['adult']?.limit,
          max_infants: +traveler_limits?.['infant']?.limit,
          max_children: +traveler_limits?.['child']?.limit,
          traveler_limit_type: props.hotel?.traveler_limit_type,
          total_traveler_limit: props.hotel?.total_traveler_limit,
          default_traveler_count: props.hotel?.default_traveler_count,
          traveler_types: props.product.traveler_types,
          outlined: true,
          'hide-bottom-space': true,
          label: t('common.travelers')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRANSFER_IDS.DESTINATION,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRANSFER_IDS.SEARCH_BTN,
        component: SearchBtn,
        hidden: false,
        disabled: false,
        model: 'btn',
        order: 7,
        events: {},
        bind: {
          label: t('common.search-now'),
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRANSFER_IDS.SEARCH_BTN,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRANSFER_IDS.PICKUP_DATE,
        component: DatePicker,
        hidden: false,
        disabled: false,
        model: 'pickup_date',
        order: 8,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          'hide-bottom-space': true,
          booking_cutoff_days: props.transfer.booking_cutoff_days,
          rules: rules.dates,
          label: t('common.pickup-date')
        },
        ...get_field_settings({
          field_id: PACKAGE_TRANSFER_IDS.PICKUP_DATE,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRANSFER_IDS.PICKUP_TIME,
        component: TimePicker,
        hidden: false,
        disabled: false,
        model: 'pickup_time',
        order: 8,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          'hide-bottom-space': true,
          rules: rules.dates,
          label: t('common.pickup-time')
        },
        ...get_field_settings({
          field_id: PACKAGE_TRANSFER_IDS.PICKUP_TIME,
          settings: field_setting,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      }
    ]
  }))
}
