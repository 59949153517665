<template>
  <div>
    <span v-if="!node.meta.carousel_id">
      Please select the carousel to control.
    </span>
    <div v-else :style="{ '--q-primary': node.meta.color }" v-bind="bind">
      <template v-if="node.meta.design === CAROUSE_CONTROL_DESIGNS[0]">
        <div class="egs-carousel-control-wrap">
          <q-btn
            unelevated
            flat
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_left"
            @click="handle_select_prev"
          />
          <div class="egs-carousel-control-default">
            <q-btn
              unelevated
              :color="
                carousel_node.meta.slide_id === item.id ? 'primary' : 'grey-8'
              "
              :flat="carousel_node.meta.slide_id !== item.id"
              :key="item.id"
              :label="index + 1"
              @click="handle_select(item)"
              v-for="(item, index) in carousel_node.meta.slides"
              :size="node.meta.size ?? 'md'"
            />
          </div>
          <q-btn
            flat
            unelevated
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_right"
            :size="node.meta.size ?? 'md'"
            @click="handle_select_next"
          />
        </div>
      </template>
      <template v-if="node.meta.design === CAROUSE_CONTROL_DESIGNS[1]">
        <div class="egs-carousel-control-wrap">
          <q-btn
            unelevated
            flat
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_left"
            :size="node.meta.size ?? 'md'"
            @click="handle_select_prev"
          />
          <div v-if="!!carousel_node" class="egs-carousel-control-dot">
            <div
              :class="[
                'egs-dot-item',
                carousel_node.meta.slide_id === item.id ? 'active' : ''
              ]"
              :key="item.id"
              @click="handle_select(item)"
              v-for="item in carousel_node.meta.slides"
            ></div>
          </div>
          <q-btn
            flat
            unelevated
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_right"
            :size="node.meta.size ?? 'md'"
            @click="handle_select_next"
          />
        </div>
      </template>
      <template v-if="node.meta.design === CAROUSE_CONTROL_DESIGNS[2]">
        <div class="egs-carousel-control-arrow">
          <q-btn
            unelevated
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_left"
            :size="node.meta.size ?? 'md'"
            @click="handle_select_prev"
          />
          <q-btn
            unelevated
            padding="xs xs"
            color="primary"
            icon="keyboard_arrow_right"
            :size="node.meta.size ?? 'md'"
            @click="handle_select_next"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import { NODE_BASE_PROPS, CAROUSE_CONTROL_DESIGNS } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'
import cloneDeep from 'lodash.clonedeep'

const props = defineProps(NODE_BASE_PROPS)
// const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const carousel_id = props.node.meta.carousel_id
const carousel_node = computed(
  () => canvas_store.nodes[carousel_id] ?? { meta: { slices: [] } }
)

const handle_select = (item) => {
  if (!!carousel_id) canvas_store.nodes[carousel_id].meta.slide_id = item.id
}

const handle_select_prev = () => {
  const slides = carousel_node.value.meta.slides
  const current_index = slides.findIndex(
    (s) => s.id === carousel_node.value.meta.slide_id
  )
  const prev_index = current_index - 1
  if (prev_index < 0) {
    canvas_store.nodes[carousel_id].meta.slide_id = slides[slides.length - 1].id
  } else {
    canvas_store.nodes[carousel_id].meta.slide_id = slides[prev_index].id
  }
}

const handle_select_next = () => {
  const slides = carousel_node.value.meta.slides
  const current_index = slides.findIndex(
    (s) => s.id === carousel_node.value.meta.slide_id
  )
  const next_index = current_index + 1
  if (next_index >= slides.length) {
    canvas_store.nodes[carousel_id].meta.slide_id = slides[0].id
  } else {
    canvas_store.nodes[carousel_id].meta.slide_id = slides[next_index].id
  }
}

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const bind = computed(() => {
  let style = cloneDeep(styles.value)

  return { style: { ...style, 'min-height': '30px' } }
})
</script>
