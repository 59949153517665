import {defineStore} from 'pinia'

export default defineStore(
  'env',
  {
    state: () => {
      return {
        CMS_URL: '',
        IAM_URL: '',
        DATA_CENTER_URL: '',
        CORE_ENGINE_URL: '',
        GROUP: '',
        TEMPLATE_ID: '',
      }
    },
    persist: true
  }
)

