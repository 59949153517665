<template lang="html">
  <div  v-bind="bind">
    <trip-form-component :node="node" @submit="handle_submit" />
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import {
  NODE_BASE_PROPS,
} from 'src/constants'
import {generate_common_node_styles} from 'src/composables/canvas'
import TripFormComponent from 'src/components/common/trip-form/index.vue'
import { stores } from 'src/stores'


const props = defineProps(NODE_BASE_PROPS)

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})


const bind = computed(() => {
  const meta = props.node.meta

  let result = {
    'style': styles.value
  }

  if (meta.bordered) {
    result['bordered'] = true
  }

  if (meta.flat) {
    result['flat'] = true
  }
  return result
})

const auth_store = stores.use_auth()
const context_store = stores.use_context()


const handle_submit = (data) => {
  if (!data) return
  if (auth_store.user.access_token) {
    data.access_token = auth_store.user.access_token
  }
  const booking_app_base_domain = context_store.app.booking_app_base_domain
  if (!booking_app_base_domain) return
  const qs = new URLSearchParams(data).toString()
  location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}`

}
</script>
