<template>
  <q-btn v-bind="btn_bind"
         :loading="submitting.in_progress"
         type="submit" no-caps>
  </q-btn>
</template>
<script setup>
import {computed, defineProps} from 'vue'
import {
  NODE_BASE_PROPS, DEFAULT_BUTTON_DESIGN,
  TEXT_BUTTON_DESIGN
} from 'src/constants'
import {
  generate_common_node_styles,
  get_form_node_from_child
} from 'src/composables/canvas'
import {stores} from 'src/stores'


const props = defineProps(NODE_BASE_PROPS)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const form_node = get_form_node_from_child(props.node)

const submitting = computed(() => {
  return canvas_store.submitting_forms[form_node.id] || {
    in_progress: false
  }
})


const styles = computed(() => {

  const meta = props.node.meta

  let result = generate_common_node_styles(props.node)

  result['width'] = '100%'

  if (meta.color && meta.design !== TEXT_BUTTON_DESIGN) result['background-color'] = meta.color
  if (meta.text_color) result['color'] = meta.text_color

  return result
})

const btn_bind = computed(() => {

  const meta = props.node.meta

  let result = {
    'style': styles.value,
    'label': meta.label[context_store.language.code],
  }


  if (meta.design && meta.design !== DEFAULT_BUTTON_DESIGN) {
    result[meta.design] = true
  }

  if (meta.size) {
    result['size'] = meta.size
  }

  return result
})

</script>
