<template>
  <q-responsive v-bind="bind" @click="handle_click">
    <slot></slot>
    <div class="q-row items-end q-pa-lg">
      <div class="full-width q-row">
        <q-item-section v-if="node.meta.place">
          <q-item-label class="text-subtitle1 text-weight-medium" lines="1">
            {{ node.meta.place.name }}
          </q-item-label>
          <q-item-label class="text-caption" lines="1">
            <span>{{ node.meta.place.long_name }}</span>
          </q-item-label>
        </q-item-section>
        <q-item-section avatar v-if="node.meta.has_price">
          <q-item-label class="text-subtitle1 text-weight-medium">$
            {{ node.meta.price }}
          </q-item-label>
          <q-item-label class="text-caption">Price From</q-item-label>
        </q-item-section>
      </div>
    </div>
    <q-dialog v-model="dialog_showed" persistent>
      <q-card style="max-width: 99vw; width: 90vw">
        <q-item>
          <q-item-section>
            <q-item-label class="text-h6">
              {{ product_name }} to {{ meta.place?.name }}
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn flat round dense icon="close" v-close-popup/>
          </q-item-section>
        </q-item>
        <q-separator/>
        <q-item class="full-width">
          <package-form
              :default_destination="meta.place"
              class="full-width"
              :packages="packages"
              :node="node"
              :color="meta.colors?.form"
              :products="products"
              :handle_submit="handle_submit"
              :product_combine="product_combine" />
        </q-item>
      </q-card>
    </q-dialog>
  </q-responsive>
</template>
<script setup>
import {computed, defineProps, ref} from 'vue'
import {NODE_BASE_PROPS} from 'src/constants'
import {stores} from 'src/stores'
import PackageForm from 'src/components/common/package-form'
import keyBy from 'lodash.keyby'

const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()
const auth_store = stores.use_auth()
const meta = computed(() => props.node.meta)

const bind = computed(() => {
  let styles = {...props.styles}
  styles['color'] = props.node.meta.colors.text
  styles['cursor'] = 'pointer'
  let result = {
    'style': styles,
  }
  result['ratio'] = props.node.meta.ratios[context_store.viewport]
  return result
})

const dialog_showed = ref(false)

const handle_click = () => {
  dialog_showed.value = true
}

const products = keyBy(context_store.products, 'code')
const packages = keyBy(context_store.packages.map(x => ({
  ...x,
  product_codes: x?.product_codes?.join('--')
})), 'product_codes')

const package_key = keyBy(context_store.packages,'id')
const product_combine = package_key?.[props.node?.meta?.package_id]?.product_codes?.join('--')
const product_name = package_key?.[props.node?.meta?.package_id]?.name

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}`
  }
}


</script>
