import {defineStore} from 'pinia'
import {ref} from 'vue'

export default defineStore(
  'translations',
  {
    state: () => {
      return {
        data: ref({})
      }
    },
    persist: true
  }
)

