import {ref} from 'vue'


export default {
  id: 'storage',
  setup: () => {
    return {
      airports: ref({}),
      places: ref({}),
      airlines: ref({})
    }
  }
}
