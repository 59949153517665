<template>
  <q-carousel
    v-model="node.meta.slide_id"
    v-bind="bind"
    transition-prev="slide-right"
    transition-next="slide-left"
    animated
    control-color="primary"
    height="auto"
    infinite
  >
    <q-carousel-slide
      v-for="slide in slides"
      :key="slide.id"
      :name="slide.id"
      class="q-pa-none"
    >
      <div ref="content" v-bind="content_bind">
        <slot />
      </div>
    </q-carousel-slide>
  </q-carousel>
</template>
<script setup>
import { computed, defineProps, watch } from 'vue'
import { NODE_BASE_PROPS, NODE_SIDES } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import cloneDeep from 'lodash.clonedeep'
import { stores } from 'src/stores'

const props = defineProps(NODE_BASE_PROPS)
const canvas_store = stores.use_canvas()
const carousel_node = computed(() => canvas_store.nodes[props.node.id])
const node = computed(() => props.node)

watch(
  () => carousel_node,
  (val) => {
    if (node.value && val.value) {
      node.value.meta.slide_id = val.value.meta.slide_id
    }
  },
  { deep: true }
)

const slides = computed(() => props.node.meta.slides)

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const bind = computed(() => {
  let style = cloneDeep(styles.value)

  Object.keys(NODE_SIDES).forEach((side) => {
    delete style[`padding-${side}`]
  })

  let result = {}

  const meta = props.node.meta
  const background = props.node.background

  if (meta.flat) {
    result['flat'] = true
  }
  if (meta.bordered) {
    result['bordered'] = true
  }
  switch (background.type) {
    case 'color':
      style['background-color'] = background.color
      break
    case 'none':
      style['background-color'] = 'transparent'
      break
    case 'single-media':
      style['background'] = `url(${background?.file?.url})`
      style['background-size'] = 'cover'
      style['background-position'] = 'center'
      break
    default:
      style['background-color'] = 'transparent'
      break
  }

  style['color'] = meta.colors.text

  result['style'] = style

  return result
})

const content_bind = computed(() => {
  let style = cloneDeep(styles.value)
  Object.keys(NODE_SIDES).forEach((side) => {
    delete style[`margin-${side}`]
  })

  let result = {
    style: style
  }

  return result
})
</script>
