import axios from 'axios'
import { stores } from 'src/stores'
import {
  EDIT_MODE,
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  PAGE_REDIRECT_EVENT_HANDLER,
  PREVIEW_MODE,
  PUBLISH_MODE
} from 'src/constants'
import { useRouter } from 'vue-router'
import { Dialog } from 'quasar'

class RequestHandler {
  constructor(base_url) {
    this.axios = axios.create({
      baseURL: base_url,
      withCredentials: true
    })
  }

  make_request_data({
    method,
    payload,
    headers = null,
    progress_handler = null,
    cancel_token = null
  }) {
    if (!headers) {
      headers = {}
    }
    if (method === 'get' || method === 'delete') {
      const params = {}
      if (payload) {
        Object.keys(payload).forEach((k) => {
          let v = payload[k]
          if (Array.isArray(v)) v = v.join(',')
          params[k] = v
        })
      }
      return [
        {
          headers: headers,
          params: params,
          cancelToken: cancel_token
        }
      ]
    } else if (method === 'post' || method === 'put' || method === 'patch') {
      const options = {}
      if (typeof progress_handler === 'function')
        options['onUploadProgress'] = progress_handler
      return [payload, { headers: headers, ...options }]
    }
  }

  async do_request({
    url,
    method,
    payload,
    headers,
    progress_handler,
    cancel_token
  }) {
    if (!payload || !(payload instanceof Object)) payload = {}

    const request_data = this.make_request_data({
      method,
      payload,
      headers,
      progress_handler,
      cancel_token
    })

    const result = {
      status: 200,
      data: null,
      error: null,
      message: null
    }

    try {
      const response = await this.axios[method](url, ...request_data)
      result.status = response.status
      result.data = response.data
    } catch (error) {
      const response = error.response || {}
      result.status = response.status || 500
      result.data = response.data || { error: 'UnknownError' }
    }
    return result
  }
}

class Api {
  constructor(base_url) {
    this.BASE_URL = base_url
    this.request_handler = new RequestHandler(this.BASE_URL)
  }

  show_expired_dialog() {
    const canvas_store = stores.use_canvas()
    const context_store = stores.use_context()

    const router = useRouter()
    const group = canvas_store?.page?.group
    const e401_redirect = context_store.template?.e401_redirect?.[group]
    Dialog.create({
      title: '<h5><b>Session expired</b></h5>',
      message: `You're seeing this because either your last session expired or invalid.`,
      cancel: false,
      persistent: true,
      html: true,
      ok: 'Close',
      style: {
        width: '1000px'
      }
    }).onOk(() => {
      if ([PUBLISH_MODE].includes(context_store.mode)) {
        if (e401_redirect?.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
          if (router) return router.push(e401_redirect?.page_endpoint)
          else {
            window.location.href =
              window.location.origin + e401_redirect?.page_endpoint
          }
        }
        if (e401_redirect?.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
          if (window) window.location.href = e401_redirect?.link
        }
      } else if ([EDIT_MODE, PREVIEW_MODE].includes(context_store.mode)) {
        if (window) window.location.reload()
      }
    })
  }

  async do_request(
    endpoint,
    { method, payload, headers, progress_handler, cancel_token }
  ) {
    const auth_store = stores.use_auth()
    const context_store = stores.use_context()

    let access_token

    if ([PUBLISH_MODE, EMBED_MODE].includes(context_store.mode)) {
      access_token = auth_store.user.access_token
    } else {
      access_token = auth_store.editor.access_token
    }

    let final_headers = {}

    if (access_token) {
      final_headers['Authorization'] = 'Bearer ' + access_token
    }

    if (headers) {
      Object.assign(final_headers, headers)
    }

    let request_params = {
      url: endpoint,
      headers: final_headers,
      method,
      payload,
      progress_handler,
      cancel_token
    }

    const response = await this.request_handler.do_request(request_params)

    if (response.status === 401) {
      auth_store.editor.access_token = null
      auth_store.editor.account = null
      auth_store.editor.accesses = {}
      auth_store.user.access_token = null
      auth_store.user.account = null
      auth_store.user.accesses = {}
      this.show_expired_dialog()
    }
    if (response.status === 400 && endpoint.includes('/auth?access_token=')) {
      this.show_expired_dialog()
    }
    return response
  }
}

export default Api
