<template>
  <div class="q-row flex items-center">
    <q-btn
      :disable="!isNil(min) && modelValue <= min"
      color="primary"
      icon="remove"
      outline
      round
      size="sm"
      @click="decrease"
    />
    <q-item-label
      :style="{ width: '40px' }"
      class="text-subtitle1 text-center"
    >{{ modelValue }}
    </q-item-label
    >
    <q-btn
      :disable="!isNil(max) && modelValue >= max"
      color="primary"
      icon="add"
      outline
      round
      size="sm"
      @click="increase"
    />
  </div>
</template>
<script setup>
import isNil from 'lodash/isNil'

const props = defineProps({
  modelValue: { type: Number, default: 0, required: true },
  min: { type: [Number, String] },
  max: { type: [Number, String] }
})
const emit = defineEmits(['update:modelValue'])

const increase = () => {
  if (isNil(props.max) || props.modelValue < props.max) {
    emit('update:modelValue', props.modelValue + 1)
  }
}

const decrease = () => {
  if (isNil(props.min) || props.modelValue > props.min) {
    emit('update:modelValue', props.modelValue - 1)
  }
}
</script>
