<template>
  <div class="egs-masonry" ref="el">
    <template v-if="loaded">
      <masonry-wall ref="masonry_wall"
                    :items="items"
                    :max-columns="max_columns"
                    :min-columns="max_columns"
                    :gap="gap"
                    :column-width="column_width">
        <template v-slot:default="item_props">
          <slot name="item" v-bind="item_props"></slot>
        </template>
      </masonry-wall>
    </template>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'

const props = defineProps({
  items: {type: Array, required: true},
  max_columns: {type: Number, default: 4},
  min_columns: {type: Number, default: 1},
  gap: {type: Number, default: 8},
})

const emit = defineEmits(['loaded'])

const el = ref(null)
const masonry_wall = ref(null)
const loaded = ref(false)

const container_width = computed(() => {
  if (!el.value) return 0
  return el.value.offsetWidth
})

const column_width = computed(() => container_width.value / props.max_columns)

watch(
  () => [...props.items],
  () => {
    loaded.value = false
    setTimeout(() => {
      loaded.value = true
    }, 100)
  },
  {deep: true}
)

onMounted(() => {
  loaded.value = true
  emit('loaded')
})

</script>
