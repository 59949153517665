<template>
  <div class="egs-backgrounds"
       ref="el"
       :style="styles">
    <template v-if="type === SINGLE_MEDIA_BACKGROUND_TYPE">
      <q-img fit="cover" height="100%" :src="modelValue.file?.url"/>
    </template>
    <template v-if="type === MULTIPLE_MEDIA_BACKGROUND_TYPE">
      <q-carousel autoplay animated infinite v-model="slide_id"
                  style="width: 100%; height: 100%">
        <q-carousel-slide :img-src="media.url"
                          :name="index"
                          v-for="(media, index) in modelValue.files"
                          :key="index">
        </q-carousel-slide>
      </q-carousel>
    </template>
    <div class="egs-backgrounds-darken-filter" v-if="darken"></div>
  </div>
</template>
<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import {
  COLOR_BACKGROUND_TYPE, DESKTOP_VIEWPORT,
  MULTIPLE_MEDIA_BACKGROUND_TYPE, NO_BACKGROUND_TYPE,
  SINGLE_MEDIA_BACKGROUND_TYPE, TABLET_VIEWPORT
} from 'src/constants'
import {stores} from 'src/stores'


const props = defineProps({
  modelValue: {type: Object, required: true},
  fixed: {type: Boolean, default: false},
  corners: {type: Object}
})

const context_store = stores.use_context()
const editor_store = stores.use_editor()
const type = computed(() => props.modelValue.type)

const el = ref(null)
const slide_id = ref(0)

const darken = computed(() => props.modelValue.darken && type.value !== NO_BACKGROUND_TYPE)

const calculate_parent_rect = () => {
  let result = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
  if (!el.value) return result
  const parent_el = el.value.parentNode
  return parent_el.getBoundingClientRect()
}

const parent_rect = ref(calculate_parent_rect())

const minimized = computed(() => editor_store.adding_layouts.value || editor_store.setting_page.value || editor_store.adding_elements.value)

const styles = computed(() => {
  let result = {}

  if (props.corners && props.corners.enable) {
    result['border-radius'] = props.corners.values.map(v => `${v}px`).join(' ')
  }

  if (props.fixed) {
    result['position'] = 'fixed'
    result['height'] = '100%'
    let width = parent_rect.value.width
    if (minimized.value && [DESKTOP_VIEWPORT, TABLET_VIEWPORT].includes(context_store.viewport)) {
      width *= 2
    }
    result['width'] = `${width}px`
  }
  else {
    result['position'] = 'absolute'
    result['top'] = '0'
    result['bottom'] = '0'
    result['left'] = '0'
    result['right'] = '0'
  }
  if (type.value === COLOR_BACKGROUND_TYPE) {
    result['background-color'] = props.modelValue.color
  }

  return result
})

watch(
  [
    () => context_store.viewport,
    () => editor_store.setting_page.value,
    () => editor_store.adding_layouts.value,
    () => editor_store.adding_elements.value,
  ],
  async () => {
    await nextTick()
    parent_rect.value = calculate_parent_rect()
  },
  {deep: true}
)

onMounted(() => {
  parent_rect.value = calculate_parent_rect()
  window.addEventListener('resize', async function () {
    await nextTick()
    parent_rect.value = calculate_parent_rect()
  })
})

</script>
