<template>
  <q-avatar class="egs-avatars" v-bind="bind">
    <template v-if="node.meta.type === 'icon'">
      <q-icon :name="node.meta.icon" :size="node.meta.icon_size ? node.meta.icon_size + 'px' : 'md'" />
    </template>
    <template v-else>
      <img :src="node.meta.file?.url">
    </template>
  </q-avatar>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'

const props = defineProps(NODE_BASE_PROPS)

const bind = computed(() => {
  let result = {}
  let styles = { ...props.styles }


  if (props.node.meta.type === 'icon') {
    if (props.node.meta.colors.icon) {
      styles['color'] = props.node.meta.colors.icon
    }
    if (props.node.meta.colors.background) {
      styles['background-color'] = props.node.meta.colors.background
    }
  }

  result['style'] = styles

  return result
})

</script>
