<template>
  <div :style="styles" class="q-row justify-center items-center text-negative"
       v-if="showed">
        <q-item-label>
          {{ error_message }}
        </q-item-label>
  </div>
</template>
<script setup>
import {computed, defineProps} from 'vue'
import {NODE_BASE_PROPS} from 'src/constants'
import {
  generate_common_node_styles,
  get_form_node_from_child
} from 'src/composables/canvas'
import {stores} from 'src/stores'


const props = defineProps(NODE_BASE_PROPS)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const form_node = get_form_node_from_child(props.node)

const showed = computed(() => {
  if (context_store.mode === 'edit') return true
  const submitting = canvas_store.submitting_forms[form_node.id]
  if (!submitting) return false
  return !!submitting.error
})

const error_message = computed(() => {
  if (context_store.mode === 'edit') return 'Error message'
  const submitting = canvas_store.submitting_forms[form_node.id]
  return submitting.message
})

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)
  return result
})


</script>
