<template>
  <div :style="styles">
    <slot></slot>
  </div>
</template>
<script setup>
import {computed, defineProps} from 'vue'
import {NODE_BASE_PROPS} from 'src/constants'
import {generate_common_node_styles} from 'src/composables/canvas'


const props = defineProps(NODE_BASE_PROPS)
const styles = computed(() => {
  let result = generate_common_node_styles(props.node)
  return result
})
</script>
