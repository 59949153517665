<template>
  <q-form v-if="payload" greedy @submit="$emit('submit', $event)">
    <div class="q-row q-col-gutter-sm">
      <div
        v-for="field in order_by(
          Object.values(fields.filter((x) => !x.hidden)),
          'order'
        )"
        :key="field.id"
        :class="class_dimension(field.dimension?.columns)"
        :style="styles(field.style)"
      >
        <component
          v-if="!field.model"
          v-bind="field.bind"
          :is="field.component"
          v-on="field.events"
          :disable="field.disabled"
        />
        <component
          v-else-if="field.model === 'payload'"
          v-model="payload"
          v-bind="field.bind"
          :is="field.component"
          v-on="field.events"
          :disable="field.disabled"
        />
        <component
          v-else-if="field.model && field.model_path"
          v-model="payload[field.model][field.model_path]"
          v-bind="field.bind"
          :is="field.component"
          v-on="field.events"
          :disable="field.disabled"
        />
        <component
          v-else
          v-model="payload[field.model]"
          v-bind="field.bind"
          :is="field.component"
          v-on="field.events"
          :disable="field.disabled"
        />
      </div>
    </div>
  </q-form>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import order_by from 'lodash.orderby'
import {
  DESKTOP_VIEWPORT,
  TABLET_VIEWPORT,
  MOBILE_VIEWPORT
} from 'src/constants'
import { stores } from 'src/stores'

const context_store = stores.use_context()
const package_store = stores.use_package()
const emit = defineEmits(['submit'])

const props = defineProps({
  fields: {
    type: Array,
    required: true,
    default: () => []
  },
  node_id: {
    type: String,
    required: true,
    default: ''
  }
})

const payload = computed(() => package_store.trip_form[props.node_id])

const class_dimension = (columns) => {
  if (columns === 0) {
    return 'hidden'
  }
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    return 'q-col-' + columns.desktop
  } else if (context_store.viewport === TABLET_VIEWPORT) {
    return 'q-col-' + columns.tablet
  } else if (context_store.viewport === MOBILE_VIEWPORT) {
    return 'q-col-' + columns.mobile
  } else {
    return 'q-col-12'
  }
}

const styles = (styles = {}) => {
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    return { ...styles, ...(styles?.desktop ?? {}) }
  } else if (context_store.viewport === TABLET_VIEWPORT) {
    return { ...styles, ...(styles?.tablet ?? {}) }
  } else if (context_store.viewport === MOBILE_VIEWPORT) {
    return { ...styles, ...(styles?.mobile ?? {}) }
  } else {
    return styles
  }
}
</script>
