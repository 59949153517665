import {ref, reactive} from "vue"

export default {
  id: "files",
  setup: {
    state: () => {
      const directories = ref({})
      const files = ref({})

      const uploading = reactive({
        triggering_uploader_meta: {},
        triggering_uploader: false,
        uploaded_files: []
      })

      return {
        uploading,
        directories,
        files,
      }
    },
  },
}
