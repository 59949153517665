<template>
  <q-tabs  v-model="active_tab" dense
          v-if="type === 'tabs'">
    <q-tab v-for="option in options" :key="option.id"
           :name="option.id"
           @click="select(option)"
           :style="gen_tab_styles(option)"
           no-caps class="text-capitalize"
           style="min-height: 32px"
           :label="$t(option.label)" />
  </q-tabs>
  <q-btn-group rounded v-else>
    <q-btn
      :style="gen_option_styles(option)"
      :text-color="is_selected(option) ? 'white' : 'black'"
      no-caps
      @click="select(option)"
      size="sm"
      class="text-capitalize"
      v-for="option in options"
      :key="option.id"
    >
      <span style="text-transform: capitalize;" :dom-key="option.label">
        {{ $t(option.label) }}
      </span>
    </q-btn>
  </q-btn-group>
</template>
<script setup>

import {ref} from 'vue'

const props = defineProps({
  modelValue: {type: Boolean},
  color: {type: String, default: '#1976d2'},
  type: {type: String, default: 'buttons'}
})

const emit = defineEmits([
  'update:modelValue'
])

const options = [
  {
    label: 'common.round-trip',
    id: 'round-trip'
  },
  {
    label: 'common.one-way',
    id: 'one-way'
  }
]

const active_tab = ref(props.modelValue ? 'round-trip' : 'one-way')

const is_selected = (option) => {
  return option.id === 'round-trip' ? props.modelValue : !props.modelValue
}

const select = (option) => {
  active_tab.value = option.id
  emit('update:modelValue', option.id === 'round-trip')
}

const gen_option_styles = option => {
  let result = {}

  let background_color = 'white'

  if (is_selected(option)) {
    background_color = props.color
  }
  result['background-color'] = background_color

  return result
}

const gen_tab_styles = option => {
  let result = {}

  let color = 'black'

  if (is_selected(option)) {
    color = props.color
  }
  result['color'] = color

  return result
}

</script>

