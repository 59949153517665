<template>
  <div v-bind="bind" class="egs-gallery-grids">
    <masonry ref="cell_container"
             v-bind="cell_container_bind">
      <template v-slot:item="{ item }">
        <cell :model-value="item" :node="node"/>
      </template>
    </masonry>
  </div>
</template>
<script setup>
import Masonry from 'src/components/common/masonry'
import Cell from './cell'

import {
  computed,
  defineProps,
  ref,
} from 'vue'
import {EDIT_MODE, NODE_BASE_PROPS, PREVIEW_MODE} from 'src/constants'
import {generate_common_node_styles, get_node_el} from 'src/composables/canvas'
import {stores} from 'src/stores'

const cell_container = ref(null)
const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)

  if (!result['min-height']) result['min-height'] = '300px'

  return result
})

const meta = computed(() => props.node.meta)

const bind = computed(() => {
  let result = {
    'style': styles.value,
  }
  return result
})

const cell_container_bind = computed(() => {
  let result = {
    'items': meta.value.cells,
    'max_columns': meta.value.columns[context_store.viewport],
  }
  const cell_gap = meta.value.cell_gap
  if (cell_gap) result['gap'] = parseInt(cell_gap)
  return result
})

</script>
