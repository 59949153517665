export const AVATAR_DESIGN = 'avatar'
export const FULL_DESIGN = 'full'
export const NAME_DESIGN = 'name'

export const DESIGNS = [
  {
    id: AVATAR_DESIGN,
    label: 'Avatar'
  },
  {
    id: FULL_DESIGN,
    label: 'Full'
  },
  {
    id: NAME_DESIGN,
    label: 'Name'
  }
]
