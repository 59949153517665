import Api from 'src/composables/api'
import { stores } from 'src/stores'

export default class AppService extends Api {
  async do_request(
    endpoint,
    { method, payload, headers, progress_handler, cancel_token }
  ) {
    const auth_store = stores.use_auth()
    if (!headers) headers = {}
    if (auth_store.user.access_token) {
      headers['Authorization'] = `Bearer ${auth_store.user.access_token}`
    }

    return super.do_request(endpoint, {
      method,
      payload,
      headers,
      progress_handler,
      cancel_token
    })
  }

  set_domain(value) {
    if (value.includes('localhost')) {
      this.BASE_URL = 'http://localhost:5000'
    } else {
      this.BASE_URL = `https://${value}/api`
    }
  }

  authenticate(payload) {
    return this.do_request(this.BASE_URL + '/auth', {
      method: 'post',
      payload
    })
  }

  register_b2b_agent(payload) {
    return this.do_request(this.BASE_URL + '/b2b/agents/register', {
      method: 'post',
      payload
    })
  }

  get_balance(payload) {
    return this.do_request(this.BASE_URL + '/balances', {
      method: 'get',
      payload
    })
  }

  list_places(payload, opt = {}) {
    return this.do_request(this.BASE_URL + '/places', {
      method: 'get',
      payload,
      cancel_token: opt?.cancel_token
    })
  }

  list_cities_with_airports(payload, opt = {}) {
    return this.do_request(this.BASE_URL + '/places/cities-with-airports', {
      method: 'get',
      payload,
      cancel_token: opt?.cancel_token
    })
  }

  fetch_language_translations(payload) {
    let request = {
      method: 'get',
      payload
    }
    return this.do_request(this.BASE_URL + '/language/json', request)
  }

  forgot_password(payload) {
    return this.do_request(this.BASE_URL + '/auth/forgot', {
      method: 'post',
      payload
    })
  }

  list_properties(payload, opt = {}) {
    return this.do_request(this.BASE_URL + '/properties', {
      method: 'get',
      payload,
      cancel_token: opt?.cancel_token
    })
  }
}
