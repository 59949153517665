export default {
  mounted(el, binding) {
    if(!window) return
    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.1, // 10% visible
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          binding.value(true);  // component is visible
        } else {
          binding.value(false); // component is not visible
        }
      });
    }, options);

    observer.observe(el);
  },
  unmounted(el) {
    if (el.__vueIntersectionObserver__) {
          el.__vueIntersectionObserver__.unobserve(el)
          delete el.__vueIntersectionObserver__
        }
  },
};
