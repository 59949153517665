<template>
<q-list :separator='separator' :class="separator ? 'egs-place-select-list' : 'egs-mb-place-select-list'">
  <template v-if="options.length">
    <template v-for="(option, index) in options" :key="option.id">
      <item @click="handle_select(option)"
            v-intersect="e => handle_intersect(e, (options.length - index) < 5)"
            :option="option"
            :mode-value="modelValue"
            :search_text="search_text"
            class="q-py-md"
        />
    </template>
  </template>

</q-list>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import Item from './item'

const props = defineProps({
  modelValue: { type: [Object, Array, String] },
  options: {type: Array, default: []},
  separator: {type: Boolean, default: false},
  fetching: {type: Boolean, default: false},
  search_text: {type: String, default: ''}
})


const emit = defineEmits(['select', 'loadmore'])
const handle_select = (option) => {
  emit('select', option)
}
const handle_intersect = (isShow, can_loadmore) => {
  if (can_loadmore && isShow && !props.fetching) {
    emit('loadmore')
  }
}

</script>
