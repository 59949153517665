import {defineStore} from 'pinia'
import {getCurrentInstance} from 'vue'

import use_env from './env'
import use_translations from './translations'
import auth from './auth'
import {init_context} from './context'
import editor from './editor'
import {init_canvas} from './canvas'
import storage from './storage'
import package_ from './package'
import file from './file'


const init_modules = (mode) => ( {
  auth,
  context: init_context(mode),
  editor,
  canvas: init_canvas(mode),
  storage,
  file,
  package: package_,
})

export let store_containers = {}

export let stores = {
  use_env,
  use_translations
}
const modules = init_modules()
Object.keys(modules).forEach(store_name => {
  const func_name = `use_${store_name}`
  if (!stores[func_name]) {
    stores[func_name] = (context_id = null) => {
      if (!context_id) {
        const app = getCurrentInstance()
        context_id = app?.root.appContext.context_id
      }
      if (!context_id) context_id = Object.keys(store_containers)[0]
      return store_containers[context_id][store_name]()
    }
  }
})

export const init_stores = (context_id, mode) => {
  let store_container = {}
  const modules = init_modules(mode)
  Object.keys(modules).forEach(store_name => {
    const store_data = modules[store_name]
    store_container[store_name] = defineStore(
      store_data.id + '_' + context_id,
      store_data.setup
    )
  })
  store_containers[context_id] = store_container
}


