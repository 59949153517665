<template>
  <q-btn class="full-width"
         :disable="disabled"
         unelevated
         text-color="white"
         :loading="in_progress"
         type="submit"
         :style="styles">
    <span>
      {{label}}
    </span>
  </q-btn>
</template>
<script setup>

import {defineProps, computed} from 'vue'

const props = defineProps({
  disabled: {type: Boolean, default: false},
  height: {type: Number, default: 40},
  color: {type: String, default: '#1976d2'},
  label: {type: String, default: 'components.shopping.forms.common.search-btn.label'},
  in_progress: {type: Boolean},
})

const styles = computed(() => {
  let result = {
    'height': `${props.height}px`,
    'background-color': props.color
  }
  return result
})

</script>
